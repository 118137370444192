#banner .banner-wrapper {
  position: relative;
  height: 600px;
  background-size: cover !important;
  background-position: center -400px !important;
  background-repeat: no-repeat !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#banner .banner-wrapper .mask {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  top: 0;
}
#banner .banner-wrapper .banner-content {
  position: relative;
  color: white;
  text-align: center;
}
#banner .banner-wrapper .banner-content .title {
  font-size: 42px;
  margin-bottom: 40px;
}
#banner .banner-wrapper .banner-content .subtitle {
  line-height: 1.6em;
}
#banner .banner-wrapper .banner-content .subtitle span {
  color: #049F77;
}
#banner .banner-wrapper .detail {
  position: absolute;
  width: 80px;
  right: 20px;
  top: 30px;
}
#banner .banner-wrapper .extra {
  position: absolute;
  font-size: 12px;
  color: white;
  right: -50px;
  font-weight: 300;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  transform: rotateZ(-90deg);
}


@media (max-width: 1313px) {
  #banner .banner-wrapper {
    background-position: center !important;
  }
}

@media (max-width: 768px) {
  #banner .banner-wrapper .banner-content .title {
    font-size: 28px;
  }
}