.footer-wrapper {
  background-color: #222222;
  padding: 50px 0;
}

.footer-wrapper footer {
  display: flex;
  justify-content: space-between;
}

.footer-wrapper footer .footer-column {
  max-width: 300px;
  width: 100%;
}
.footer-wrapper footer .footer-column h3 {
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.footer-wrapper footer .footer-column h3 a {
  color: white;
}
.footer-wrapper footer .footer-column iframe {
  width:100%;
  border:0;
  overflow:hidden;
}
.footer-wrapper footer .footer-column h4 {
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
}
.footer-wrapper footer .footer-column p {
  color: #f2f2f2;
  font-size: 12px;
  margin-top: 10px;
  line-height: 1.6em;
}
.footer-wrapper footer .footer-column .footer-social {
  color: white;
  text-decoration: none;
  font-size: 14px;
}
.footer-wrapper .commitments {
  margin-top: 40px;
  max-width: 820px;
  width: 100%;
}
.footer-wrapper .commitments h3 {
  color: white;
  font-size: 16px;
}
.footer-wrapper .commitments p {
  color: white;
  font-size: 12px;
  text-align: justify;
  hyphens: auto;
  line-height: 1.6em;
}
.footer-wrapper footer .socials, .footer-wrapper footer .phones {
  display: flex;
  flex-direction: column;
  line-height: 1.6em;
}

.copyright {
  border-top: 1px solid white;
  padding-top: 40px;
  width: 100%;
  margin-top: 50px
}
.copyright .copyright-content {
  display: flex;
  justify-content: space-between; 
}

.copyright .copyright-content span, .copyright .copyright-content span a {
  color: white;
  text-decoration: none;
  font-size: 14px;
}

.copyright .copyright-content .social-links a {
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  margin-left: 25px;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .footer-wrapper footer {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 30px;
  }

  .copyright .copyright-content {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .copyright .copyright-content .social-links {
    order: 1;
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .copyright .copyright-content .social-links a {
    margin: 10px;
  }
}