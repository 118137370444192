#enterNow {
  background: linear-gradient(to bottom, #049F77 60%, white 40%);
  padding: 100px 0;
}

#enterNow .title {
  color: white;
  text-align: center;
  margin-bottom: 75px;
  line-height: 1.6em;
}

#enterNow .options-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 75px;
}
#enterNow .option {
  background: white;
  padding: 30px;
  border-radius: 5px;
  display: flex;
  min-height: 130px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0px 2px 0px #c5c5c5;
}
#enterNow .option .header {
  text-align: center;
}
#enterNow .option .title {
  color: black;
  font-size: 14px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 20px;
}
#enterNow .option .subtitle {
  color: #049F77;
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

#enterNow .option .btn-access {
  background-color: #049F77;
  color: white;
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0;
  border-radius: 5px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  #enterNow .title {
    font-size: 26px;
  }
  #enterNow .options-content {
    grid-template-columns: 1fr;
  }
}