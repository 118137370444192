#privatePayroll {
  position: relative;
}

#privatePayroll #firstSection {
  display: grid;
  grid-template-columns: 36% 64%;
  padding-top: 20px;
}

#privatePayroll #firstSection .first-content {
  padding-right: 25px;
}
#privatePayroll #firstSection .first-content .img-content {
  position: relative;
  right: 93px;
  width: 500px;
  height: 350px;
  overflow: hidden;
}
#privatePayroll #firstSection .first-content .img-content img {
  position: absolute;
  width: 800px;
  height: auto;
  left: 0;
  top: -50px;
}

#privatePayroll #firstSection .second-content {
  padding-left: 25px;
}
#privatePayroll #firstSection .second-content .title {
  color: #049F77;
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 40px;
}
#privatePayroll #firstSection .second-content .subtitle {
  color: #2E2D2B;
  font-size: 22px;
  font-weight: 500;
  margin-top: 50px;
}
#privatePayroll #firstSection .second-content .text {
  font-size: 15px;
  line-height: 1.6em;
  text-align: justify;
  hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
}

/* Second Section */
#privatePayroll #secondSection {
  display: grid;
  grid-template-columns: 36% 64%;
  margin-bottom: 25px;
}

#privatePayroll #secondSection .first-content {
  padding-right: 25px;
}
#privatePayroll #secondSection .first-content .card {
  background-color: #049F77;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  margin-bottom: 25px;
}
#privatePayroll #secondSection .first-content .card .alert-icon {
  fill: white;
  width: 140px;
  margin-bottom: 40px;
}
#privatePayroll #secondSection .first-content .card h4 {
  color: white;
  font-size: 22px;
  line-height: 1.6em;
}
#privatePayroll #secondSection .first-content .card p {
  color: white;
  font-size: 20px;
  line-height: 1.6em;
}
#privatePayroll #secondSection .first-content .img-content {
  position: relative;
  right: 93px;
  width: 500px;
  height: 350px;
  overflow: hidden;
}
#privatePayroll #secondSection .first-content .img-content img {
  position: absolute;
  width: 720px;
  height: auto;
  top: -40px;
  left: 0;
}
#privatePayroll #secondSection .second-content {
  position: relative;
  padding-left: 25px;
}
#privatePayroll #secondSection .second-content .green-border {
  background: #049F77;
  width: 100px;
  height: 556px;
  position: absolute;
  right: -130px;
}
#privatePayroll #secondSection .second-content .title {
  color: #049F77;
  font-size: 28px;
  margin-top: 30px;
}
#privatePayroll #secondSection .second-content .inner-content {
  display: grid;
  grid-template-columns: 30% 5% 30% 5% 30%;
  height: 300px;
  margin-top: 40px;
}
#privatePayroll #secondSection .second-content .inner-content .arrow {
  position: relative;
  fill: #049F77;
  width: 30px;
  top: 100px;
}
#privatePayroll #secondSection .second-content .inner-content .sub-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
#privatePayroll #secondSection .second-content .inner-content .sub-content .title {
  color: #2E2D2B;
  font-size: 14px;
  margin-top: 20px 0;
}
#privatePayroll #secondSection .second-content .inner-content .sub-content .text {
  font-size: 12px;
  line-height: 1.6em;
}
/* Form */
#privatePayroll .form-content {
  width: 100%;
}
#privatePayroll .form-content .title {
  background: #049F77;
  color: white !important;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px !important;
  padding: 30px 0;
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 30px;
  cursor: pointer;
}
#privatePayroll .form-content form {
  width: 100%;
  display: none;
}
#privatePayroll .form-content form input, #privatePayroll .form-content textarea {
  background: #EFEFEF;
  width: 100%;
  border: none;
  margin-bottom: 5px;
  padding: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
#privatePayroll .form-content form input::placeholder {
  font-family: 'Montserrat', sans-serif;
}
#privatePayroll .form-content form input[name="company"] {
  margin-right: 5px;
}
#privatePayroll .form-content form textarea {
  resize: none;
  height: 100px;
}
#privatePayroll .form-content form textarea::placeholder {
  font-family: 'Montserrat', sans-serif;
}
#privatePayroll .form-content form .column-input {
  display: flex;
  flex-direction: column;
}
#privatePayroll .form-content form .row-input {
  display: flex;
  justify-content: space-between;
}
#privatePayroll .form-content form .btn-submit {
  background: #049F77;
  color: white;
  padding: 15px 60px;
  font-weight: 600;
  border: none;
  margin-top: 20px;
}

@media screen and (max-width: 1350px) {
  #privatePayroll #firstSection .first-content .img-content {
    /* width: 450px; */
  }
}

@media screen and (max-width: 1250px) {
  #privatePayroll #firstSection .first-content .img-content {
    width: 450px;
    right: 50px;
  }
  #privatePayroll #firstSection .first-content .img-content img {
    
  }
}
@media screen and (max-width: 1200px) {
  #privatePayroll #firstSection .first-content .img-content{
    right: 200px;
  }
}

@media screen and (max-width: 992px) {
  #privatePayroll #firstSection {
    grid-template-columns: 1fr;
  }
  #privatePayroll #firstSection .first-content {
    display: none;
  }
  #privatePayroll #firstSection .second-content {
    padding-left: 0;
  }

  #privatePayroll #secondSection {
    grid-template-columns: 1fr;
    margin-top: 40px;
  }
  #privatePayroll #secondSection .first-content {
    padding-right: 0;
  }
}

@media screen and (max-width: 768px) {
  #privatePayroll #secondSection .first-content .card h4 {
    font-size: 18px;
    text-align: center;
  }
  #privatePayroll #secondSection .first-content .card p {
    text-align: center;
    font-size: 14px;
  }
  #privatePayroll #secondSection .second-content {
    padding-left: 0;
  }
  #privatePayroll #secondSection .second-content .inner-content {
    grid-template-columns: 1fr;
    height: auto;
  }
  #privatePayroll #secondSection .second-content .inner-content .sub-content p {
    max-width: 350px;
    border-image-width: 100%;
  }
  #privatePayroll #secondSection .second-content .title,
  #privatePayroll #secondSection .second-content .subtitle {
    text-align: center;
  }
  #privatePayroll #secondSection .second-content .green-border {
    display: none;
  }
  #privatePayroll #secondSection .second-content .arrow {
    display: none;
  }
}