#toCompany .resume {
  background-color: #049F77;
  padding: 50px 0;
  text-align: center;
}
#toCompany .resume .title, #toCompany .resume .subtitle {
  color: white;
}
#toCompany .resume .title {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 40px;
  font-size: 28px;
}
#toCompany .resume .subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6em;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}

#toCompany .card-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  padding: 75px 0;
  border-radius: 5px;
}
#toCompany .card-content .first-card  {
  background-color: #049F77;
  padding: 30px;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 3px;

}
#toCompany .card-content .first-card .icon {
  width: 100px;
  fill: white !important;
  margin-bottom: 20px;
}
#toCompany .card-content .first-card .title {
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
  line-height: 1.4em;
}

#toCompany .card-content .card {
  position: relative;
  background-color: #8F8F8F;
  color: white;
  border-radius: 3px;
  padding: 0 25px;
  min-height: 200px;
  margin-bottom: 20px;
  padding-top: 70px;
  padding-bottom: 30px;
  text-align: center;
}
#toCompany .card-content .card .icon {
  background: white;
  position: absolute;
  width: 70px;
  left: 50%;
  transform: translateX(-50%);
  top: -40px;
  border: 8px solid white;
  border-radius: 50%;
}
#toCompany .card-content .card .title {
  font-size: 20px;
  margin-bottom: 20px;
}

#toCompany .card-content .card .text {
  font-size: 11px;
  line-height: 2em;
}

#toCompany .partner-content {
  background: #049F77;
  max-width: 550px;
  width: 100%;
  height: 75px;
  line-height: 75px;
  margin: 0 auto;
  margin-bottom: 5  0px;
  cursor: pointer;
}
#toCompany .partner-content .title {
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
}

#toCompany .form-section {
  padding: 40px 0;
}
#toCompany .form-section .title {
  background: #049F77;
  color: white;
  max-width: 550px;
  width: 100%;
  height: 75px;
  line-height: 75px;
  margin: 0 auto;
  margin-bottom: 50px;
  cursor: pointer;
  text-align: center;
}

#toCompany .form-content {
  width: 70%;
  margin: 0 auto;
}
#toCompany .form-content form {
  width: 100%;
  display: none
}
#toCompany .form-content form input, #toCompany .form-content textarea {
  background: #EFEFEF;
  width: 100%;
  border: none;
  margin-bottom: 5px;
  padding: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
#toCompany .form-content form input::placeholder {
  font-family: 'Montserrat', sans-serif;
}
#toCompany .form-content form input[name="company"] {
  margin-right: 5px;
}
#toCompany .form-content form textarea {
  resize: none;
  height: 100px;
}
#toCompany .form-content form textarea::placeholder {
  font-family: 'Montserrat', sans-serif;
}
#toCompany .form-content form .column-input {
  display: flex;
  flex-direction: column;
}
#toCompany .form-content form .row-input {
  display: flex;
  justify-content: space-between;
}
#toCompany .btn-content {
  display: flex;
  justify-content: center;
}
#toCompany .form-content form .btn-submit {
  background: #049F77;
  color: white;
  padding: 15px 60px;
  font-weight: 600;
  border: none;
  margin-top: 20px;
  font-family: 'Montserrat', sans-serif;
}

@media screen and (max-width: 992px) {
  #toCompany .card-content {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 768px) {
  #toCompany .card-content {
    grid-template-columns: repeat(2, 1fr);
  }
  #toCompany .form-content {
    width: 100%;
  }
  #toCompany .form-content .row-input {
    flex-direction: column;
  }

  #toCompany .form-title {
    font-size: 16px;
  }
  #toCompany .card-content .card .text {
    font-size: 14px;
  }
}
@media screen and (max-width: 550px) {
  #toCompany .card-content {
    grid-template-columns: 1fr;
  }
  #toCompany .resume .title {
    font-size: 24px;
  }
  #toCompany .resume .subtitle {
    font-size: 14px;
    width: 100%;
  }
  #toCompany .form-section .title {
    font-size: 18px;
  }
}