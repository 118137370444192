#hero {
  background-color: #292929;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  position: relative;
  z-index: 1;
}
#hero .mask {
  background: rgba(41, 41, 41, 0.8);
  width: 100%;
  height: 100%;
  position: absolute;
  display: none;
  left: 0;
  top: 0;
}
#hero .hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  max-width: 600px;
}
#hero .hero-content .hero-title {
  color: #049F77;
  font-size: 38px;
  margin: 0;
  margin-bottom: 15px;
}
#hero .hero-detail {
  position: absolute;
  left: 15px;
  bottom: 15px;
  width: 50px;
}
#hero .hero-content .hero-subtitle {
  color: white;
  font-size: 22px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 15px;
}
#hero .hero-content .hero-text {
  color: white;
  max-width: 380px;
  line-height: 1.6em;
}

#hero .hero-wrapper {
  display: flex;
  height: 600px;

}

#hero .hero-image {
  width: 600px;
  height: 600px;
  overflow: hidden;
  position: absolute;
  right: 0;
}
#hero .hero-image img {
  position: absolute;
  width: 900px;
  left: -100px;
  height: auto;
}

@media screen and (max-width: 1200px) {
  #hero .hero-image {
    right: -100px;
  }
}

@media screen and (max-width: 992px) {
  #hero .hero-image {
    display: none;
  }
  #hero .mask  {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  #hero .hero-image {
    display: none;
  }
}
@media screen and (max-width: 550px) {
  #hero .hero-content .hero-title {
    font-size: 28px;
  }
  #hero .hero-content .hero-subtitle {
    font-size: 16px;
  }
  #hero .hero-content .hero-text {
    font-size: 12px;
  }
}