#home .hero-content {
  background: #444;
}
#home .hero-content .swiper-button-prev, #home .hero-content .swiper-button-next {
  width: 30px;
  height: 30px;
  border: 1px solid white;
  padding: 5px;
  border-radius: 50%;
  color: white !important;
}
#home .hero-content .swiper-button-prev:after, #home .hero-content .swiper-button-next:after {
  font-size: 18px;
}
#home .home-wrapper {
  background: linear-gradient(to top, white 70%, #049F77 30%);
  padding: 60px 0;
}
#home .home-wrapper .first-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 80px;
}
#home .home-wrapper .first-section .title-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#home .home-wrapper .first-section .title-wrapper .title {
  color: white;
  font-size: 32px;
  margin-bottom: 20px;
}
#home .home-wrapper .first-section .title-wrapper span {
  color: white;
  font-size: 18px;
}

#home .home-wrapper .first-section .simulation {
  background: white;
  padding: 20px;
  border-radius: 5px;
}

#home .home-wrapper .first-section .simulation .title {
  color: #049F77;
  text-align: center;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 700;
}

#home .simulation .form-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
}

#home .simulation .form-control {
  margin-bottom: 20px;
  width: 100%;
}

#home .simulation .form-control .slider {
  background: #049F77;
  overflow: hidden;
  width: 99.5%;
  border-radius: 50px;
  padding: 0;
}
#home .simulation .form-control .slider::-ms-track {
  appearance: none;
  color: #444;
  transition: box-shadow 0.2s ease-in-out;
}
#home .simulation .form-control .slider::-ms-thumb {
  width: 20px;
  -webkit-appearance: none;
  
  height: 20px;
  cursor: ew-resize;
  background: #fff;
  box-shadow: -330px 0 0 320px #049F77, inset 0 0 0 20px #037055;
  border-radius: 50%;
  transition: box-shadow 0.2s ease-in-out;
  position: relative;
}
#home .simulation .form-control .slider::-moz-range-track {
  appearance: none;
  color: #444;
  transition: box-shadow 0.2s ease-in-out;
}
#home .simulation .form-control .slider::-moz-range-thumb {
  width: 20px;
  -webkit-appearance: none;
  
  height: 20px;
  cursor: ew-resize;
  background: #fff;
  box-shadow: -330px 0 0 320px #049F77, inset 0 0 0 20px #037055;
  border-radius: 50%;
  transition: box-shadow 0.2s ease-in-out;
  position: relative;
}
#home .simulation .form-control .slider::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  color: #444;
  transition: box-shadow 0.2s ease-in-out;
}
#home .simulation .form-control .slider::-webkit-slider-thumb {
  width: 40px;
  -webkit-appearance: none;
  
  height: 20px;
  cursor: ew-resize;
  background: #fff;
  box-shadow: -330px 0 0 320px #049F77, inset 0 0 0 20px #037055;
  border-radius: 50%;
  transition: box-shadow 0.2s ease-in-out;
  position: relative;

}
#home .simulation .form-control .min-max {
  color: #444;
  display: flex;
  justify-content: space-between;
}
#home .simulation .form-control .value-result {
  color: #444;
}
#home .simulation .form-control .value-result p {
  margin: 0;
  margin-bottom: 10px;
}
#home .simulation .form-control .value-result span {
  color: #049F77;
  font-weight: bold;
}
#home .simulation .btn-control {
  display: flex;
  justify-content: center;
}
#home .simulation .btn-simulate {
  background: #049F77;
  color: white;
  border: none;
  height: 40px;
  width: 200px;
  margin: 0 auto;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

#home .simulation .btn-simulate:focus {
  outline: 0;
}

#home .home-wrapper .second-section .card-wrapper {
  display: grid;
  grid-template-columns: 25% 5% 25% 5% 25%;
  grid-gap: 3vw;
  margin-bottom: 60px;
}

#home .home-wrapper .second-section .card-wrapper .card {
  background: #F7F6F6;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
#home .home-wrapper .second-section .card-wrapper .arrow {
  position: relative;
  fill: #049F77;
  width: 50px;
  top: 140px;
}
#home .home-wrapper .second-section .card-wrapper .card img {
  width: 100px;
  margin-bottom: 30px;
}
#home .home-wrapper .second-section .card-wrapper .card .title {
  color: #049F77;
}
#home .home-wrapper .second-section .card-wrapper .card .text {
  line-height: 1.6em;
}

#home .home-wrapper .second-section .section-title {
  text-align: center;
  font-size: 24px;
  padding-top: 40px;
  padding-bottom: 40px;
  text-transform: uppercase;
}
#home .home-wrapper .second-section .how-it-works {
  display: flex;
  align-items: center;
  grid-gap: 40px;
  justify-content: center;
}
#home .home-wrapper .second-section .how-it-works .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
#home .home-wrapper .second-section .how-it-works .arrow {
  fill: #049F77;
  width: 80px;
}
#home .home-wrapper .second-section .how-it-works .content .icon {
  width: 100px;
  margin-bottom: 15px;
}
#home .home-wrapper .second-section .how-it-works .content .title {
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
}

@media screen and (max-width: 992px) {
  #home .home-wrapper .second-section .card-wrapper {
    grid-gap: 50px;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 50px;
  }
  #home .home-wrapper .second-section .card-wrapper .arrow {
    display: none;
  }
  #home .home-wrapper .second-section .card-wrapper .card img {
    width: 60px;
  }
  #home .home-wrapper .second-section .card-wrapper .card .title {
    font-size: 16px;
  }
  #home .home-wrapper .second-section .card-wrapper .card .text {
    font-size: 12px;
  }

  #home .home-wrapper .second-section .how-it-works {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  #home .home-wrapper .second-section .how-it-works .arrow {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  #home .home-wrapper .first-section {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
  }

  #home .home-wrapper .second-section .card-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 550px) {
  #home .home-wrapper .second-section .card-wrapper {
    grid-template-columns: 1fr;
  }
  #home .home-wrapper .second-section .card-wrapper .card img {
    width: 80px;
  }
  #home .home-wrapper .second-section .card-wrapper .card .title {
    font-size: 18px;
  }
  #home .home-wrapper .second-section .card-wrapper .card .text {
    font-size: 14px;
  }

  #home .home-wrapper .second-section .how-it-works {
    grid-template-columns: 1fr;
  }

  #home .simulation .form-group {
    grid-template-columns: 1fr;
  }
}

