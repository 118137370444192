#simulation {
  background: #383838;
  padding-top: 50px;
  padding-bottom: 10px;
}

#simulation .form-content {
  max-width: 500px;
  width: 100%;
}

#simulation .title {
  color: #049F77;
  margin-bottom: 20px;
}
#simulation .subtitle {
  color: white;
  text-transform: uppercase;
  line-height: 1.6em;
  font-size: 14px;
  margin: 40px 0;
}
#simulation .form-control {
  max-width: 500px;
  width: 100%;
  margin-bottom: 20px;
}
#simulation .form-control input {
  max-width: 500px;
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  font-family: 'Montserrat', sans-serif;
}
#simulation .form-control input:focus {
  outline: 0;
}
#simulation .form-control .slider {
  background: #049F77;
  overflow: hidden;
  max-width: 498px;
  width: 100%;
  border-radius: 50px;
  padding: 0;
}
#simulation .form-control .slider::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  color: #444;
  transition: box-shadow 0.2s ease-in-out;
}
#simulation .form-control .slider-content {
  position: relative;
}
/* #simulation .form-control .slider-content .real-slider {
  left: 10px;
}
#simulation .form-control .slider-content .slider {
  position: absolute;
  left: 0;
  opacity: 0;
  width: 100%;
} */

#simulation .form-control .slider::-webkit-slider-thumb {
  width: 40px;
  -webkit-appearance: none;
  
  height: 20px;
  cursor: ew-resize;
  background: #fff;
  box-shadow: -330px 0 0 320px #049F77, inset 0 0 0 20px #037055;
  border-radius: 50%;
  transition: box-shadow 0.2s ease-in-out;
  position: relative;

}
#simulation .form-control .min-max {
  color: white;
  display: flex;
  justify-content: space-between;
}
#simulation .form-control .value-result {
  color: white;
}
#simulation .form-control .value-result span {
  color: #049F77;
  font-weight: bold;
}
#simulation .form-content .btn-content {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}
#simulation .form-content .btn-send {
  background-color: #049F77;
  color: white;
  border: none;
  padding:  12px 50px;
  margin-bottom: 50px;
  text-transform: uppercase;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif ;
}
#simulation .form-content .btn-send:focus {
  outline: 0em;
}

#simulation .radio-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 10px;
  margin-bottom: 10px;
}
#simulation .radio-group label:hover input ~ .content  {
  background: #049F77;
  color: white;
}
#simulation .radio-group input {
  display: none;
}

#simulation .radio-group .content {
  background: white;
  color: #686868;
  width: 100%;
  font-size: 13px;
  text-align: center;
  padding: 12px 0;
  cursor: pointer;
  transition: .2s;
}

#simulation .radio-group label input:checked ~ .content {
  background: #049F77;
  color: white;
}

@media screen and (max-width: 768px) {
  #simulation .radio-group {
    grid-template-columns: 1fr;
  }
}