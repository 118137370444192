#formSection {
  padding: 40px 0;
}
#formSection .form-title {
  background: #049F77;
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  padding: 30px 0;
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 30px;
  cursor: pointer;
}

#formSection .form-content {
  width: 70%;
  margin: 0 auto;
}
#formSection .form-content form {
  width: 100%;
  display: none
}
#formSection .form-content form input, .form-content textarea {
  background: #EFEFEF;
  width: 100%;
  border: none;
  margin-bottom: 5px;
  padding: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
#formSection .form-content form input::placeholder {
  font-family: 'Montserrat', sans-serif;
}
#formSection .form-content form input[name="company"] {
  margin-right: 5px;
}
#formSection .form-content form textarea {
  resize: none;
  height: 100px;
}
#formSection .form-content form textarea::placeholder {
  font-family: 'Montserrat', sans-serif;
}
#formSection .form-content form .column-input {
  display: flex;
  flex-direction: column;
}
#formSection .form-content form .row-input {
  display: flex;
  justify-content: space-between;
}
#formSection .btn-content {
  display: flex;
  justify-content: center;
}
#formSection .form-content form .btn-submit {
  background: #049F77;
  color: white;
  padding: 15px 60px;
  font-weight: 600;
  border: none;
  margin-top: 20px;
  font-family: 'Montserrat', sans-serif;
}

@media screen and (max-width: 768px) {
  #formSection .form-content {
    width: 100%;
  }
  #formSection .form-content .row-input {
    flex-direction: column;
  }

  #formSection .form-title {
    font-size: 16px;
  }
}