.header-wrapper {
  background-color: #1D1D1B;
  height: 100px;
  display: flex;
  align-items: center;
}

.header-wrapper header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 30px;
}
.header-wrapper header .logo {
  display: flex;
  align-items: center;
}
.header-wrapper header .logo img {
  width: 120px;
  height: auto;
}

.header-wrapper .menu-wrapper {
  display: flex;
  align-items: center;
}
.header-wrapper .menu-wrapper .menu {
  list-style-type: none;
  display: flex;
  padding-left: 0;
  margin-left: 0;
  margin-right: 20px;
}
.header-wrapper .menu-wrapper .menu .menu-link {
  margin-right: 25px;
  font-size: 12px;
}
.header-wrapper .menu-wrapper .menu .menu-link a {
  color: white;
  font-weight: 600;
  text-decoration: none;
}
.header-wrapper .menu-wrapper .menu .menu-link a.active {
  color: #049F77;
}
.header-wrapper .menu-wrapper .sign-in {
  color: white;
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 12px;
  border: 1px solid white;
}

/* Mobile */
.header-wrapper .mobile-menu-wrapper {
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100vh;
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  z-index: 2;
}
.header-wrapper .mobile-menu-wrapper.active {
  display: flex;
}

.header-wrapper .mobile-menu-wrapper .menu-content {
  background: #1D1D1B;
  height: 100%;
  max-width: 400px;
  width: 100%;
  position: absolute;
  right: 0;
}

.header-wrapper .mobile-menu-wrapper .menu .menu-close {
  color: white;
  position: absolute;

  font-size: 20px;

  right: 15px;
  top: 15px;
  cursor: pointer;
}

.header-wrapper .mobile-menu-wrapper .menu {
  list-style-type: none;
  padding: 60px 30px;
}

.header-wrapper .mobile-menu-wrapper .menu img {
  width: 180px;
  margin-bottom: 30px;
}

.header-wrapper .mobile-menu-wrapper .menu .menu-link {
  margin-bottom: 15px;
}
.header-wrapper .mobile-menu-wrapper .menu .menu-link a {
  color: white;
  text-decoration: none;
  font-weight: 600;
}
.header-wrapper .mobile-menu-wrapper .menu .menu-link a:hover {
  color: #049F77;
}
.header-wrapper .mobile-menu-wrapper .menu .menu-link a.active {
  color: #049F77;
}

.header-wrapper .mobile-menu-wrapper .social-wrapper {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin-top: 40px;
}
.header-wrapper .mobile-menu-wrapper .social-wrapper .social-link {
  margin: 10px 0;
}
.header-wrapper .mobile-menu-wrapper .social-wrapper .social-link a {
  color: white;
  text-decoration: none;
  font-weight: 600;
}
.header-wrapper .mobile-menu-wrapper .social-wrapper .social-link a:hover {
  color: #049F77;
}
.hamburger-menu {
  position: relative;

  display: none;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  z-index: 2;
}
.hamburger-menu .line {
  position: relative;
  background: white;
  width: 30px;
  height: 4px;
}
.hamburger-menu .line:nth-child(1) {
  margin-bottom: 5px; 
}

.hamburger-menu.active .line:nth-child(1) {
  transform: rotateZ(45deg);
  top: 10px;
}
.hamburger-menu.active .line:nth-child(2) {
  transform: rotateZ(-45deg);
  top: 1px;
}

@media screen and (max-width: 992px) {
  .header-wrapper .menu-wrapper .menu {
    display: none;
  }
  .hamburger-menu {
    display: flex;
  }
  .header-wrapper .menu-wrapper .sign-in {
    display: none;  
  }
  .header-wrapper .mobile-menu-wrapper {
    display: none;
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .header-wrapper header {
    padding: 0 15px;
  }
}