#privacyPolicy {
  padding-bottom: 60px;
}
#privacyPolicy .divisor {
  background: #049F77;
  width: 100%;
  height: 20px;
}
#privacyPolicy .title {
  color: #049F77;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 60px;
}
#privacyPolicy .text {
  font-size: 18px;
  line-height: 1.6em;
}