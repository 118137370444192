#useTerms {
  padding-bottom: 60px;
}
#useTerms .divisor {
  background: #049F77;
  width: 100%;
  height: 20px;
}
#useTerms .title {
  color: #049F77;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 60px;
}
#useTerms .text {
  font-size: 18px;
}