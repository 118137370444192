#toYou .resume {
  background-color: #049F77;
  padding: 50px 0;
  text-align: center;
}
#toYou .resume .title, #toYou .resume .subtitle {
  color: white;
}
#toYou .resume .title {
  margin-bottom: 10px;
  font-size: 28px;
}
#toYou .resume .subtitle {
  font-size: 16px;
  font-weight: 600;
}

#toYou .video-content {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 100px;
  margin-bottom: 50px;
}

#toYou .video-content iframe {
  max-width: 800px;
  width: 100%;
  height: 450px;
}

#toYou .cards-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 100px 0;
  grid-gap: 20px;
  grid-column-gap: 30px;
}
#toYou .cards-content .card {
  padding: 30px 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0px 2px 0px #c5c5c5;
}
#toYou .cards-content .card .icon {
  width: 80px;
  fill: #049F77;
}
#toYou .cards-content .card .title {
  margin: 20px 0;
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
}
#toYou .cards-content .card .text {
  margin: 0;
  text-align: center;
  font-size: 10px;
  line-height: 20px;
}

@media screen and (max-width: 992px) {
  #toYou .cards-content {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 550px) {
  #toYou .cards-content {
    grid-template-columns: 1fr;
  }
  #toYou .resume .title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  #toYou .resume .subtitle {
    font-size: 14px;
  }
}

@media screen and (max-width: 550px) {
  #toYou .video-content iframe {
    height: 350px;
  }
}