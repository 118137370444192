#popup-wrapper {
  background: rgba(0, 0, 0, 0.4);

  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

#popup-wrapper .popup-content {
  background: #11A27B;

  position: relative;

  max-width: 450px;
  width: 100%;

  margin: 0 15px;
  padding: 50px 70px;
  
  border-radius: 8px;

  text-align: center;
}
#popup-wrapper .popup-content .close {
  color: white;
  font-size: 40px;
  position: absolute;
  right: 15px;
  top: 0px;
  cursor: pointer;
}

#popup-wrapper .popup-content .title {
  color: white;
  font-size: 28px;
  line-height: 1.2em;
  text-transform: uppercase;
}

#popup-wrapper .popup-content .message {
  color: white;
  font-weight: 300px;
  margin: 30px 0;
  line-height: 1.6em;
}

#popup-wrapper .popup-content .logo {
  width: 120px;
  height: auto;
}