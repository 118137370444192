#aboutUs .resume-wrapper {
  padding: 30px 0;
}

#aboutUs .resume-wrapper .resume {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
}
#aboutUs .resume-wrapper .resume .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;
}
#aboutUs .resume-wrapper .resume .content .icon {
  width: 100px;
  margin-bottom: 20px;
}
#aboutUs .resume-wrapper .resume .content .title {
  color: #049F77;
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 700;
}
#aboutUs .resume-wrapper .resume .content .text {
  line-height: 1.6em;
}
#aboutUs .about-us-wrapper {
  background: linear-gradient(to bottom, #049F77 72%, white 28%);
  padding: 60px 0;
  padding-bottom: 80px;
}
#aboutUs .about-us-wrapper .about-us-content {
  color: white;
  text-align: center;
  padding: 0 15%;
  padding-bottom: 60px;
}
#aboutUs .about-us-wrapper .about-us-content .title {
  font-size: 34px;
  text-transform: uppercase;
}
#aboutUs .about-us-wrapper .about-us-content .text {
  line-height: 1.6em;
}
#aboutUs .about-us-wrapper .about-us-content span {
  font-weight: 600;
}
#aboutUs .about-us-wrapper .contact {
  background-color: #222222;
  color: white;
  padding: 50px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#aboutUs .about-us-wrapper .contact span {
  font-size: 22px;
  font-weight: 600;
}
#aboutUs .about-us-wrapper .contact .btn-contact {
  color: white;
  font-size: 14px;
  text-decoration: none;
  background: transparent;
  padding: 12px 30px;
  border-radius: 50px;
  box-shadow: 0 0 0 2px #049F77;
}

@media screen and (max-width: 768px) {
  #aboutUs .resume-wrapper .resume {
    grid-template-columns: 1fr;
  }
  #aboutUs .about-us-wrapper .about-us-content {
    padding: 0 5%;
    padding-bottom: 50px;
  }
}