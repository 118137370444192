.faq-content {
  cursor: pointer;
}
.faq-content .faq-question {
  display: flex;
  border: 1.5px solid #d4d4d4;
  height: 90px;
}

.faq-content .faq-question .question {
  color: black;
  font-size: 12px;
  line-height: 1.6em;
  text-transform: uppercase;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.faq-content .faq-question .faq-button {
  background: #049F77;
  color: white;
  font-size: 22px;
  width: 75px ;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1.5px solid #d4d4d4;
  transition: .4s;
}
.faq-content .faq-question .faq-button i {
  transition: .4s;
}

.faq-content .faq-answer {
  font-size: 12px;
  line-height: 1.6em;
  border: 1px solid #dadada;
  padding: 15px;
  height: 150px;
  display: none;
}

@media screen and (max-width: 768px) {
  
}

@media screen and (max-width: 550px) {
  .faq-content .faq-question .question {
    font-size: 9px;
  }
}