#help .form-wrapper {
  position: relative;
  background-size: cover !important;
  background-position: center -60px !important;
  background-repeat: no-repeat !important;
  overflow: hidden;
  padding: 50px 0;
}
#help input, #help textarea {
  font-family: 'Montserrat', sans-serif;
}
#help .form-wrapper .mask {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  top: 0;
}
#help .form-wrapper .form-content {
  position: relative;
  z-index: 1;
}

#help .form-wrapper .form-content .title {
  color: white;
  font-size: 32px;
  margin-bottom: 40px;
  line-height: 1.4em;
}
#help .form-wrapper .form-content .title span {
  color: #049F77;
}
#help .form-wrapper .form-content .form-control {
  background-color: white;
  padding: 10px 20px;
  max-width: 500px;
  border-radius: 5px;
  margin-bottom: 30px;
}
#help .form-wrapper .form-content .form-control input,
#help .form-wrapper .form-content .form-control textarea  {
  background-color: white;
  color: black;
  font-weight: 500;
  border: none;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  resize: none;
}
#help .form-wrapper .form-content .form-control textarea {
  padding: 0;
  padding-top: 10px;
}
#help .form-wrapper .form-content .form-control input::placeholder,
#help .form-wrapper .form-content .form-control textarea::placeholder {
  color: black;
  font-weight: 700;
}
#help .form-wrapper .form-content .form-control input:focus,
#help .form-wrapper .form-content .form-control textarea:focus {
  outline: none;
}
#help .form-wrapper .form-content .btn-send {
  background-color: #049F77;
  color: white;
  border: none;
  padding: 15px 30px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1.5px;
}

#help .search-wrapper {
  background: #049F77;
  padding: 50px 0;
}
#help .search-wrapper .search-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#help .search-wrapper .search-content .title {
  color: white;
  font-size: 30px;
}
#help .search-wrapper .search-content .search-input {
  display: flex;
  align-items: center;
  max-width: 350px;
  width: 100%;
}
#help .search-wrapper .search-content .search-input input {
  width: 100%;
  height: 35px;
  padding: 5px;
  padding-left: 10px;
  border: none;
  border-radius: 5px 0 0 5px;
  font-family: 'Montserrat', sans-serif;
}
#help .search-wrapper .search-content .search-input input:focus {
  outline: 0;
}
#help .search-wrapper .search-content .search-input .icon-search {
  background: white;
  border: none;
  padding: 5px;
  width: 19px;
  height: 19px;
  padding: 8px;
  border-radius: 0 5px 5px 0;
}
#help .search-wrapper .search-content .search-input .btn-search:focus {
  outline: 0;
}

#help .faq-wrapper {
  padding: 60px 0;
}

#help .faq-wrapper .title {
  color: #049F77;
  font-size: 28px;
}

#help .faq-wrapper .subtitle {
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 10px;
}

#help .faq-wrapper .faq-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 40px;
  grid-column-gap: 50px;
  grid-row-gap: 20px;
}

@media screen and (max-width: 992px) {
  #help .faq-wrapper .faq-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  #help .faq-wrapper .faq-container {
    grid-template-columns: 1fr;
  }
  #help .search-wrapper .search-content {
    flex-direction: column;
  }
  #help .search-wrapper .search-content .title {
    margin-bottom: 20px;
    font-size: 26px;
  }
}